import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { CardType, PaymentError } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import Confetti from "@/components/common/confetti";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import Support from "@/components/common/support";
import UserContext from "@/contexts/User/UserContext";
import { convertToDate, convertToTime } from "@/utils/dateUtils";
import { isBrowser } from "@/utils/env";
import { cardTypeToString, currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";

import {
  getPaymentFromProfile,
  getPaymentFromProfileVariables,
} from "./graphql/__generated__/getPaymentFromProfile";
import {
  setDefaultCardAfterPaymentFromProfile,
  setDefaultCardAfterPaymentFromProfileVariables,
} from "./graphql/__generated__/setDefaultCardAfterPaymentFromProfile";
import { GET_PAYMENT_FROM_PROFILE } from "./graphql/GET_PAYMENT_FROM_PROFILE";
import { SET_DEFAULT_CARD_FROM_PROFILE } from "./graphql/SET_DEFAULT_CARD_FROM_PROFILE";
import { PaymentResultProps } from "./types";
import "./styles.scss";

const PaymentResult = ({ isSuccess, isFailure, paymentId, successUrl }: PaymentResultProps) => {
  const { isUserLoggedIn } = useContext(UserContext);

  const [getPayment, {
    data: getPaymentData, error: getPaymentError, loading: getPaymentLoading,
  },
  ] = useLazyQuery<getPaymentFromProfile, getPaymentFromProfileVariables>(GET_PAYMENT_FROM_PROFILE);

  const [setDefaultCard] = useMutation<
  setDefaultCardAfterPaymentFromProfile, setDefaultCardAfterPaymentFromProfileVariables
  >(SET_DEFAULT_CARD_FROM_PROFILE, {
    errorPolicy: "all", // todo: make general behaviour for network errors
  });

  const [areDetailsOpened, setAreDetailsOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!isUserLoggedIn) {
      if (isBrowser()) {
        navigate("/");
      }
    } else if (paymentId) {
      getPayment({ variables: { paymentID: paymentId } });
    }
  }, [getPayment, isUserLoggedIn, paymentId]);

  useEffect(() => {
    if (!isFailure && getPaymentData?.getPayment?.card && !getPaymentError && !getPaymentLoading) {
      setDefaultCard({ variables: { cardID: getPaymentData.getPayment.card.id } });
    }
    if (getPaymentError) {
      navigate("/404", { replace: true });
    }
  }, [getPaymentData, getPaymentError, getPaymentLoading, isFailure, setDefaultCard]);

  const handleButtonClick = () => {
    if (isSuccess) {
      if (successUrl) {
        navigate(successUrl);
      } else {
        navigate("/profile/balance", {
          state: {
            isSuccessPayment: true,
          },
        });
      }
    }
    if (isFailure) {
      navigate("/profile/payment", {
        state: {
          isSuccessPayment: false,
        },
      });
    }
  };

  const handleDetailsClick = () => {
    setAreDetailsOpened((oldVal) =>
      !oldVal);
  };

  const {
    amount: { amount, currency },
    datetime,
    card,
    errorValue,
  } = getPaymentData?.getPayment ?? {
    amount: {},
    card: {
      type: undefined,
      number: undefined,
    },
  };

  const showSuccessForm = isSuccess && currency && errorValue === PaymentError.NoError;

  return (
    <>
      <Helmet>
        {isSuccess && <title>Платёж прошёл успешно</title>}
        {isFailure && <title>Платёж отклонён</title>}
        {!isSuccess && !isFailure && <title>Результат платежа</title>}
        <html className="html--full-height" lang="ru" />
        <body className="footer--hide body--full-height" />
      </Helmet>
      {showSuccessForm && <Confetti />}
      {getPaymentLoading && <Loader />}
      {!!getPaymentData && (
        <div className="profile-payment-result__container">
          <div className="profile-payment-result">
            <div className="profile-payment-result__middle">
              {showSuccessForm && (
              <>
                <div className="profile-payment-result__success-circle">
                  <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size24} />
                </div>
                <h3 className="profile-payment-result__success-header">
                  Платёж
                  {" "}
                  {amount ? `на ${priceToString(amount)} ${currencyToString(currency)}\n` : ""}
                  прошёл успешно
                </h3>
              </>
              )}

              {isFailure && currency && (
                <>
                  <div className="profile-payment-result__failure-circle">
                    <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size24} />
                  </div>
                  <h3 className="profile-payment-result__failure-header">
                    Платёж
                    {" "}
                    {amount ? `на ${priceToString(amount)} ${currencyToString(currency)}\n` : ""}
                    отклонён
                  </h3>

                  {errorValue === PaymentError.InsufficientFundsOnBalance && (
                  <div className="profile-payment-result__text">
                    Недостаточно средств. Пополните карту
                    {" "}
                    <br />
                    {" "}
                    или воспользуйтесь другой.
                  </div>
                  )}

                  {errorValue !== PaymentError.CommonError && (
                  <div className="profile-payment-result__text">Произошла ошибка</div>
                  )}
                </>
              )}

              <div
                className={areDetailsOpened
                  ? "profile-payment-result__details is-opened"
                  : "profile-payment-result__details"}
                onClick={handleDetailsClick}
              >
                <span>Детали</span>
                {areDetailsOpened && (
                  <div className="profile-payment-result__table">
                    {card && (
                      <div className="profile-payment-result__row">
                        <div className="profile-payment-result__column">
                          <label>
                            <Icon type={IconTypeEnum.Card} size={IconSizeEnum.Size20} />
                            <span>Карта</span>
                          </label>
                        </div>
                        <div className="profile-payment-result__column">
                          <span>
                            {cardTypeToString(card.type ?? CardType.Unknown)}
                            {" "}
                            •
                            {card.number}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="profile-payment-result__row">
                      <div className="profile-payment-result__column">
                        <label>
                          <Icon type={IconTypeEnum.Number} size={IconSizeEnum.Size20} />
                          <span>Платёж</span>
                        </label>
                      </div>
                      <div className="profile-payment-result__column">
                        <span>{paymentId}</span>
                      </div>
                    </div>
                    {datetime && (
                      <div className="profile-payment-result__row">
                        <div className="profile-payment-result__column">
                          <label>
                            <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size20} />
                            <span>Дата</span>
                          </label>
                        </div>
                        <div className="profile-payment-result__column">
                          <span>
                            {convertToTime(datetime)}
                            {" "}
                            {convertToDate(datetime)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="profile-payment-result__bottom">
              <Button
                className="profile-payment-result__button"
                text={isFailure ? "Вернуться к оплате" : "Продолжить"}
                size={ButtonSizeEnum.Large}
                color={ButtonColorEnum.Dark}
                onClick={handleButtonClick}
              />
              {isFailure && (
                <Support isShort />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentResult;
